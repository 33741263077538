<template>
  <div class="edit_box">
    <div id="header">
      <div @click="$router.go(-1)">
        <van-icon name="arrow-left" size="24" />
      </div>
      <div style="font-size:16px;">修改地址</div>
      <div>
        <van-icon name="arrow-left" size="24" color="#fff" />
      </div>
    </div>
    <van-address-edit
      :area-list="areaList"
      show-delete
      show-set-default
      show-search-result
      :address-info="addressInfo"
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @delete="onDelete"
    />
    <div class="address_bottom_2">
      <div class="btn " @click="editConfirm">确认修改</div>
      <div class="btn" @click="delConfirm" style="background-color:#D9D9D9;">删除地址</div>
    </div>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      areaList,
      addressInfo: {},
      postAddress: {}
    };
  },
  created() {
    let addressInfo = JSON.parse(window.sessionStorage.getItem("addressList"))[
      window.location.href.split("?index=")[1]
    ];
    this.addressInfo = addressInfo;
  },
  methods: {
    editConfirm(){
      let btn = window.document.getElementsByClassName('van-address-edit__buttons')
      //return console.log(btn)
      btn[0].childNodes[0].click()
    },
    delConfirm(){
      let btn = window.document.getElementsByClassName('van-address-edit__buttons')
      btn[0].childNodes[1].click()
    },
    onSave(x) {
      this.postAddress = {
        id: x.id,
        name: x.name,
        tel: x.tel,
        province: x.province,
        city: x.city,
        county: x.county,
        address: x.addressDetail,
        default: x.isDefault,
        province_code: x.areaCode.slice(0,2),
        city_code: x.areaCode.slice(2,4),
        county_code: x.areaCode.slice(4,6)
      };
      console.log(this.postAddress)
      this.aditAddress()
    },
    onDelete() {
      this.deleteAddress()
    },
    async aditAddress(){
      let {data:res} = await this.$http.post('api/editAddress',this.postAddress)
      if(res.statusCode !== 0){
        return this.$toast.fail(res.msg)
      }
      this.$toast.success('修改成功')
      this.$router.go(-1)
    },
    async deleteAddress(){
      let {data:res} = await this.$http.post('api/delAddress',{
        id:this.addressInfo.id
      })
      if(res.statusCode !== 0){
        return this.$toast.fail(res.msg)
      }
      let address_id = window.sessionStorage.getItem('addressId')
      if(address_id == this.addressInfo.id){
        window.sessionStorage.setItem('addressId','')
      }
      this.$toast.success('删除成功')
      this.$router.go(-1)
    }
  }
};
</script>

<style lang="less">
.edit_box {
  text-align: left;
  background-color: #f9f9f9;
  height: 100vh;
  padding-top: 5.5rem;
  .van-address-edit__buttons{
    display: none;
  }
  .van-switch--on{
    background-color:var(--fc--);
  }
  .address_bottom_2{
    position: fixed;
    top: 600px;
    left:0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    .btn{
      width: 8.75rem;
      height: 2rem;
      color: #fff;
      background-color:var(--fc--);
      border-radius: 2rem;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>